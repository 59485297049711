import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/** IMPORT COMPONENTS */
import { CONSTANT } from '../config/CONSTANT'
import { SocketContext } from './SocketContext'
import API from '../API'
import { ResponseUtils } from '../utils/responseUtils'
import { getCommercialTerms } from '../API/getCommercialTerms'

const AuthContext = React.createContext()
const getInitialState = () => ({
  user: null,
  refreshToken: null,
  isLoading: false,
  countries: [],
})

const getTermsInitial = () => ({
  terms: [],
  errorResponse: '',
  newProductUpdate: [],
})
const getStateShoppingInformation = () => ({
  shoppingData: {},
})

const getLeadTimeInitial = () => ({
  leadTimeValue: 0,
})

function AuthProvider({ children }) {
  const [state, setState] = useState(getInitialState())
  const [stateTerms, setStateTerms] = useState(getTermsInitial())
  const [stateLeadTime, setStateLeadTime] = useState(getLeadTimeInitial())
  const [stateShoppingData, setStateShoppingData] = useState(getStateShoppingInformation())
  const { t } = useTranslation('register', { useSuspense: false })
  const responseUtils = new ResponseUtils()

  const { disconnect } = useContext(SocketContext)
  const history = useHistory()

  useEffect(() => {
    const userPrev = JSON.parse(localStorage.getItem(CONSTANT.PROFILE_KEY))
    if (userPrev) {
      setState((prevState) => ({ ...prevState, user: userPrev }))
    } else {
      getProfileUser()
    }
    getCountries()
    getTerms()
  }, [])

  async function getProfileUser() {
    try {
      const user = await API.getProfile()
      if (user) {
        updatedUserProfile(user)
        return user
      }
    } catch (e) {
      // logOut(true)
      console.log(e)
    }
  }

  async function getUser() {
    if (user) {
      return user
    } else {
      return await getProfileUser()
    }
  }

  async function logIn({ token, user, refreshToken }) {
    localStorage.setItem(CONSTANT.TOKEN_KEY, `${token}`)
    user.isSeller = false
    user.manufacturer = false
    if (!user.roleName) {
      user.roleName = getPreferredRole(user)
    }

    localStorage.setItem(CONSTANT.USER_KEY, JSON.stringify(user))
    localStorage.setItem(CONSTANT.REFRESH_TOKEN, refreshToken)
    await getProfileUser()
    return setState((prevState) => ({ ...prevState, user }))
  }

  function getPreferredRole(user) {
    if (user.roles) {
      if (user.roles.includes(CONSTANT.ROLES.SELLER)) {
        user.isSeller = true
        return CONSTANT.ROLES.SELLER
      } else if (user.roles.includes(CONSTANT.ROLES.MANUFACTURER)) {
        user.manufacturer = true
        return CONSTANT.ROLES.MANUFACTURER
      } else if (user.roles.includes(CONSTANT.ROLES.CUSTOMER_SERVICE)) {
        return CONSTANT.ROLES.CUSTOMER_SERVICE
      } else if (user.roles.includes(CONSTANT.ROLES.SALES_AGENT)) {
        return CONSTANT.ROLES.SALES_AGENT
      } else if (user.roles.includes(CONSTANT.ROLES.BUYER)) {
        return CONSTANT.ROLES.BUYER
      } else {
        return null
      }
    }
  }

  function logOut(routerLogin = false) {
    cleanStorage(false)
    disconnect()
    history.push(routerLogin ? '/login' : '/')
    setTimeout(() => {
      return setState((prevState) => ({ ...prevState, user: null }))
    }, 500)
  }

  function cleanStorage(clearState = true) {
    localStorage.removeItem(CONSTANT.TOKEN_KEY)
    localStorage.removeItem(CONSTANT.USER_KEY)
    localStorage.removeItem(CONSTANT.PROFILE_KEY)
    localStorage.removeItem(CONSTANT.REFRESH_TOKEN)
    if (clearState) {
      return setState((prevState) => ({ ...prevState, user: null }))
    }
  }

  function updatedUserProfile(userResponse) {
    const user = userResponse && userResponse.user ? userResponse.user : userResponse
    //TODO update with keycloack
    localStorage.setItem(CONSTANT.USER_KEY, JSON.stringify(user))
    localStorage.setItem(CONSTANT.PROFILE_KEY, JSON.stringify(user))
    setState((prevState) => ({ ...prevState, user }))
  }

  function updatedShoppindData(shoppingResponse) {
    setStateShoppingData({ shoppingData: shoppingResponse })
  }

  async function getCountries() {
    let countries = await API.getCountriesNew()
    countries = countries.map((country) => {
      const { lang, isoCode } = country
      return { ...country, description: lang[0]?.name, value: isoCode }
    })
    setState({ ...state, countries })
    getTerms()
  }

  function getTerms() {
    getCommercialTerms()
      .then((res) => {
        if (res.success) {
          const terms = res.data
          setStateTerms({ terms: terms })
        } else {
          const { error } = responseUtils.responseError(res.code)
          setStateTerms({ errorResponse: t(`register:errors.${error.lang}`, error.text) })
        }
      })
      .catch((err) => {
        console.error(`error: ${err}`)
      })
  }

  function handleSendNewProductsArray(product) {
    return setStateTerms({ newProductUpdate: product })
  }

  function handleLeadTime(leadTime) {
    return setStateLeadTime(leadTime)
  }

  const { user, isLoading, countries } = state
  const { terms, errorResponse, newProductUpdate } = stateTerms
  const { leadTimeValue } = stateLeadTime
  const { shoppingData } = stateShoppingData

  return (
    <AuthContext.Provider
      value={{
        user,
        logIn,
        logOut,
        isLoading,
        cleanStorage,
        countries,
        terms,
        errorResponse,
        handleSendNewProductsArray,
        leadTimeValue,
        handleLeadTime,
        newProductUpdate,
        updatedUserProfile,
        updatedShoppindData,
        shoppingData,
        getProfileUser,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }